<template>
    <div class="payment-shipping-fee">
        <edit :api="'settings'" :fs="paymentFields" :id="'shipping-fee'" @saved="saved" ref="edit" title="Deliver Fee Settings"></edit>
    </div>
</template>
<script>
import Edit from "@/components/Edit";
import { paymentFields } from "@/config";
import Submit from "@/components/Submit";

export default {
    components: {
        Edit,
        Submit
    },
    computed: {
        paymentFields() {
            return [
                { heading: "Min Delivery Fee", data: "value.min_shipping_fee", type: "number", validate: "required" },
                { heading: "Min Miles Radius", data: "value.min_radius", type: "number", validate: "required" },
                { heading: "$ Rates Per Mile", data: "value.miles_rate", type: "number", validate: "required" },
                { heading: "Miles Multi Times", data: "value.miles_times", type: "number", validate: "required" }
            ];
        }
    },
    methods: {
        saved() {
            this.$refs["edit"].load();
        }
    },
    created() {}
};
</script>
